@import "../../App.css";
 
.login_con {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/images/img/bg.jpg) center center no-repeat;
    background-size: cover;
}
.login_con .logo {
    position: fixed;
    left: 5%;
    top: 5%;
}
.login_con .logo img {
    display: block;
    height: 35px;
}
.login_con .logo p {
    font-family: Arial;
    font-size: 35px;
    color: #fff;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbbbbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaaaaa, 0 6px 1px rgb(0 0 0 / 10%), 0 0 5px rgb(0 0 0 / 10%), 0 1px 3px rgb(0 0 0 / 30%), 0 3px 5px rgb(0 0 0 / 20%), 0 5px 10px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 20%), 0 20px 20px rgb(0 0 0 / 15%);
}
.login_con .title {
    position: fixed;
    left: 30.2%;
    top: 51%;
    transform: translate(-50%,-50%);
    line-height: initial;
}
.login_con .title p {
    font-family: Arial;
    font-size: 35px;
    color: #fff;
}
.login_con .con {
    overflow: hidden;
    position: fixed;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    padding: 2% 0;
    border-radius: 20px;
    background: linear-gradient(to right, rgba(18,83,165,0.9) 0%, rgba(18,83,165,0.8) 100%);
}
.login_con .con .tab {
    overflow: hidden;
    margin-bottom: 5%;
    display: flex;
    line-height: 50px;
    text-align: center;
}
.login_con .con .tab a {
    display: block;
    flex: 1;
    font-size: 19px;
    color: #999;
    cursor: pointer;
}
.login_con .con .tab a.cur {
    color: #fff;
}
.login_con .con .list {
    overflow: hidden;
    padding: 0 40px;
}
.login_con .con .list .item {
    overflow: hidden;
    display: none;
}
.login_con .con .list .item .ant-form-item {flex: 1; margin-bottom: 0;}
.login_con .con .list .item .ant-form-item-control-input-content {
    display: flex;
}
.login_con .con .list .item a {
    font-size: 16px;
    color: #fff;
}
.login_con .con .list .item ul {
    overflow: hidden;
    margin-bottom: 15px;
    min-width: 400px;
}
.login_con .con .list .item ul li {
    overflow: hidden;
    display: flex;
    padding: 5px;
    line-height: 55px;
    margin-bottom: 5%;
}
.login_con .con .list .item ul li:nth-last-child(1) {
    margin-bottom: 0;
}
.login_con .con .list .item ul li input {
    flex: 1;
    padding: 0 20px 0 0;
    line-height: 55px;
    text-indent: 60px;
    font-size: 17px;
    border-radius: 10px;
    border: none;
    background-position: 20px center !important;
    background-repeat: no-repeat !important;
    transition: .5s all linear;
}
.login_con .con .list .item ul li input.user {
    background-image: url(../../assets/images/img/login_user.png);
}
.login_con .con .list .item ul li input.password {
    background-image: url(../../assets/images/img/login_password.png);
}
.login_con .con .list .item ul li input:focus {
    box-shadow: 0 0 5px #000;
}
.login_con .con .list .item #btnSendCode {
    display: block;
    flex: initial;
    text-indent: initial;
    font-size: 14px;
    color: #333;
    width: 150px;
    height: 55px;
    padding: 0 20px;
    text-align: center;
    margin-left: 15px;
    border-radius: 10px;
    background: #a0daee;
    border: none;
    cursor: pointer;
    transition: .5s all linear;
}
.login_con .con .list .item #btnSendCode:hover {
    box-shadow: 0 0 5px #a0daee;
}
.login_con .con .list .item .btn {
    display: block;
    width: 100%;
    line-height: 50px;
    font-size: 20px;
    color: #fff;
    border-radius: 25px;
    background: #306ce5;
    border: none;
    text-align: center;
    cursor: pointer;
    transition: .5s all ease;
}
.login_con .con .list .item .btn:hover {
    box-shadow: 0 0 5px #306ce5;
}
.login_con .con .list .item .codeRefresh {
    display: block;
    width: 150px;
    height: 55px;
    margin-left: 15px;
    border-radius: 10px;
    cursor: pointer;
}