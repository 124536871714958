@import "../../App.css";

.order_list {
  overflow: hidden;
  background: #f1f2f6;
}

.order_list .search {
  overflow: hidden;
  margin-bottom: 1.5%;
}
.order_list .search dl {
  overflow: hidden;
  float: left;
  line-height: 34px;
  margin-right: 30px;
}
.order_list .search dl dd {
  overflow: hidden;
  float: left;
  font-size: 15px;
  color: #fff;
  background: #7f8187;
  padding: 0 20px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}
.order_list .search dl:nth-last-child(1) dd:nth-child(1) {
  padding: 0;
}
.order_list .search dl:nth-last-child(1) dd:nth-child(2) {
  padding: 0;
}
.order_list .search dl:nth-last-child(1) dd button {
  display: block;  
  border: none;
  color: #fff;
  padding: 0 20px;
  height: auto;
  line-height: 34px;
  background: #306ce5;
}
.order_list .search dl dd:nth-last-child(1) {
  margin-right: 0;
}
/* .order_list .search dl dd:nth-child(1) {
  background: #7f8187;
}
.order_list .search dl dd:nth-child(2) {
  background: #ff8000;
}
.order_list .search dl dd:nth-child(3) {
  background: #306ce5;
} */
.order_list .search div {
  overflow: hidden;
  float: left;
}
.order_list .search div input {
  display: block;
  float: left;
  width: 300px;
  padding: 0 15px;
  line-height: 34px;
  border: none;
  border-radius: 5px;
  margin-right: 15px;
}
.order_list .search div dl dd {
  color: #1d244b;
  background: #dcdfe1 !important;
}

.order_list .ant-list-items {
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
}
.order_list .ant-list-items li {
  position: relative;
  top: 0;
  /* flex: 0 0 32.2%; */
  width: 32%;
  padding: 10px;
  margin: 0 2% 1% 0;
  background: #fafcda;
  border-radius: 10px;
  transition: 0.5s all ease;
}
.order_list .ant-list-items li:hover {
  top: -5px;
  box-shadow: 0 0 15px #ccc;
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  transform: scale(1.03);
}
.order_list .ant-list-items li:nth-child(3n) {
  margin: 0 0 1% 0;
}
.order_list .ant-list-items li .ant-ribbon-wrapper {
  position: initial;
  width: 100%;
}
.order_list .ant-list-items li .ant-ribbon {
  right: -8px;
  top: -5px;
  font-size: 14px;
  height: 26px;
  line-height: 25px;
}
.order_list .ant-list-items li .t {
  overflow: hidden;
  display: flex;
  width: 100%;
}
.order_list .ant-list-items li .l {
  overflow: hidden;
  flex: 1;
  margin-right: 3%;
}
.order_list .ant-list-items li .title {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 3%;
}
.order_list .ant-list-items li .title span {
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border-radius: 50px;
  margin-right: 10px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}
.order_list .ant-list-items li .title span.s1 {
  background: #ff8000;
}
.order_list .ant-list-items li .title span.s2 {
  background: #306ce5;
}
.order_list .ant-list-items li .title span.s3 {
  background: #38ad5f;
}
.order_list .ant-list-items li .title span.s4 {
  background: #d61818;
}
.order_list .ant-list-items li .title p {
  flex: 1;
  font-size: 17px;
  color: #1d244b;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.order_list .ant-list-items li .con {
  overflow: hidden;
  margin: 0 3%;
}
.order_list .ant-list-items li .con .name {
  margin-bottom: 2%;
}
.order_list .ant-list-items li .con .txt {
  overflow: hidden;
  margin-bottom: 2%;
}
/* .order_list .ant-list-items li .con .txt div {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 3%;
  align-items: center;
} */
.order_list .ant-list-items li .con .txt div .anticon {
  float: left;
  margin-right: 10px;
}
.order_list .ant-list-items li .con .txt div p {
  flex: 2;
  font-size: 15px;
  font-weight: bold;
  color: #1d244b;
}
.order_list .ant-list-items li .con .txt dl dd {
  display: flex;
  background: none !important;
  padding: 0;
  margin-bottom: 6px;
}
.order_list .ant-list-items li .con .txt dl dd .ant-statistic {
  margin: 0;
}
.order_list .ant-list-items li .con .txt dl dd .ant-statistic .ant-statistic-content-value {
  display: block;
  font-size: 14px !important;
  width: auto !important;
  height: auto !important;
  line-height: 21px !important;
}
.order_list .ant-list-items li .con .txt dl dd .anticon {
  display: block;
  float: left;
  margin-right: 10px;
}
.order_list .ant-list-items li .con .txt dl dd:hover {
  box-shadow: initial !important;
  cursor: initial !important;
}
.order_list .ant-list-items li .con .txt dl dd:nth-last-child(1) {
  margin-bottom: 0;
}
.order_list .ant-list-items li .con .txt div:nth-child(2) {
  margin: 2% auto;
}
.order_list .ant-list-items li .con .txt div:nth-child(2) span:nth-child(1) {
  flex: initial;
  font-size: 16px;
  font-weight: initial;
  width: 50px;
  height: 30px;
  line-height: 28px;
  margin: 0;
}
.order_list .ant-list-items li .op {
  overflow: hidden;
  padding: 5px;
  margin-top: 4%;
}
.order_list .ant-list-items li .op dl {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
}
.order_list .ant-list-items li .op dd {
  overflow: hidden;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 5px 10px 5px 7px;
  margin-bottom: 7px;
  border-radius: 5px;
  transition: 0.5s all ease;
  cursor: pointer;
}
.OrderBtnTransfer {
  background: #288499;
}
.OrderBtnTransfer:hover {
  box-shadow: 0 0 5px #288499;
}
.OrderBtnTechnology {
  background: #386dff;
}
.OrderBtnTechnology:hover {
  box-shadow: 0 0 5px #386dff;
}
.OrderBtnPic {
  background: #e62a89;
}
.OrderBtnPic:hover {
  box-shadow: 0 0 5px #e62a89;
}
.OrderBtnDelete {
  background: #e62129;
}
.OrderBtnDelete:hover {
  box-shadow: 0 0 5px #e62129;
}
.TakeOrders {
  background: #1296db;
}
.TakeOrders:hover {
  box-shadow: 0 0 5px #1296db;
}
.OrderBtnSee {
  background: #306ce5;
}
.OrderBtnSee:hover {
  box-shadow: 0 0 5px #306ce5;
}
.OrderBtnDismantling {
  background: #ff8000;
}
.OrderBtnDismantling:hover {
  box-shadow: 0 0 5px #ff8000;
}
.order_list .ant-list-items li .op dd img {
  display: block;
  width: 24px;
  margin-right: 7px;
}
.order_list .ant-list-items li .op dd p {
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
.order_list .ant-list-items li .remarks {
  float: right;
  font-size: 16px;
  color: #1d244b;
  margin: 0 3%;
  text-align: right;
}
.order_list .loadMore {
  overflow: hidden;
  font-size: 0;
  text-align: center;
  margin: 2% auto;
}
.order_list .loadMore a {
  display: inline-block;
  font-size: 16px;
  border: 1px solid #dcdcdc;
  background: #fff;
  padding: 0 30px;
  line-height: 50px;
  border-radius: 5px;
}
