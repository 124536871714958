@import "../../App.css";

.default_view {overflow: hidden; position: relative; width: 100%; height: 100%; background: linear-gradient(-130deg, #14a2ab 0%, #1940af 100%);}
.default_view .default_view_con {overflow: hidden;}
.default_view .default_view_con .animation {position: absolute; left: 5%; top: 35%; transform: translateY(-50%); width: 28%;}
.default_view .default_view_con .animation img {display: block; width: 100%;}
.default_view .default_view_con .animation .animation1 {position: relative; display: block; z-index: 10;}
.default_view .default_view_con .animation .animation2 {position: absolute; width: 100%; right: -85%; top: -5%;}
.default_view .default_view_con .animation .animation3 {position: absolute; width: 70%; top: 75%; z-index: 11;}
.default_view .default_view_con .animation .animation4 {position: absolute; width: 80%; right: -70%; top: 90%;}
.default_view .default_view_con .title {position: absolute; right: 5%; top: 50%; transform: translateY(-50%); font-size: 30px; color: #fff;}

.default_view .default_view_con .label {position: absolute; left: 50%; bottom: 2%; transform: translateX(-50%); font-size: 16px; color: rgba(255, 255, 255, .5);}