@import "../../App.css";

.login_menu {overflow: hidden; position: relative;}

.login_menu .ant-avatar {width: auto; border-radius: initial;}
.login_menu .ant-avatar > img {width: auto;}
.login_menu .operation {margin-right: 60px;}
.login_menu .operation dd {float: left; margin-right: 60px; cursor: pointer;}
.login_menu .operation dd:nth-last-child(1) {margin-right: 0;}
.login_menu .operation dd div .ant-badge {float: left;}
.login_menu .operation dd div img {display: block; float: left; height: 32px;}
.login_menu .operation dd div .name {float: left; font-size: 16px; color: #000; line-height: 32px; margin-left: 15px;}

.menu {overflow: hidden; position: fixed; top: 72px; width: 100%; height: 100%; background: url(../../assets/images/img/login_menu_bg.jpg) center center no-repeat;}
.menu .title {position: absolute; left: 5%; top: 17%; width: 90%; font-size: 60px; color: #fff; font-weight: bold; text-align: center;}
.menu ul {overflow: hidden; position: absolute; left: 50%; top: 57%; transform: translate(-50%,-50%); font-size: 0; width: 85%; text-align: center;}
.menu ul li {overflow: hidden; display: inline-block; width: 18%; text-align: center;}
.menu ul li img {display: block; width: 60%; margin: 0 auto;}
.menu ul li p {font-size: 22px; color: #000; line-height: 60px;}