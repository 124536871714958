@import '../../App.css';

.ant-tabs {
  height: 100%;
}
.ant-tabs-content-holder .ant-tabs-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: auto;
  height: 300px !important;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.site-page-header .logo {
  overflow: hidden;
  float: left;
  padding: 10px;
  margin-top: -4px;
}
.site-page-header .logo img {
  display: block;
  height: 32px;
}
.site-page-header .logo p {
  font-family: Arial;
  font-size: 32px;
  color: #fff;
}
.site-page-header .logo_menu {
  overflow: hidden;
  float: left;
  margin-left: 20px;
}
.site-page-header .logo_menu .ant-menu {
  font-size: 14px;
}
.site-page-header .logo_menu .ant-menu-horizontal > .ant-menu-item a {
  font-size: 14px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: none;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 5px;
}

.admin_body .menu_btn {
  overflow: hidden;
  background: #fff;
  padding: 8px 20px;
  margin-right: 10px;
  border-radius: 5px;
}
.topMenu .ant-tabs-content-holder {
  margin: 12px 12px 0;
}
.topMenu .ant-tabs-nav-operations {
  padding: 0 !important;
  height: auto !important;
  background: initial !important;
}
/* .admin_body .ant-avatar {width: auto; border-radius: initial;} */
/* .admin_body .ant-avatar > img {width: auto;} */
.admin_body .operation {
  margin-right: 20px;
}
.admin_body .operation dd {
  float: left;
  margin-right: 30px;
  cursor: pointer;
}
.admin_body .operation dd:nth-last-child(1) {
  margin-right: 0;
}
.admin_body .operation dd div .ant-badge {
  float: left;
}
.admin_body .operation dd div img {
  display: block;
  float: left;
  height: 32px;
}
.admin_body .operation dd div .ant-avatar {
  float: left;
  margin-right: 0;
}
.admin_body .operation dd div .name {
  float: left;
  font-size: 15px;
  color: #fff;
  line-height: 32px;
  padding-left: 10px;
}
.admin_body .operation dd div .anticon {
  float: left;
  font-size: 32px;
  color: #fff;
}

.admin_body .Header {
  display: flex;
}
.admin_body .Header .trigger {
  margin: 23px 20px 0 0;
}
.admin_body .Header .ant-page-header {
  flex: 1;
  margin-top: 12px;
  padding: 0;
}
.admin_body .ant-page-header {
  padding: 13px 24px;
}
.admin_body .ant-page-header .ant-page-header-heading-left {
  margin: 0;
}

.ant-layout-header .anticon svg {
  width: 1.4em;
  height: 1.4em;
}
.admin_body .header .logo {
  overflow: hidden;
  float: left;
  margin: 20px 20px 0 0;
}
.admin_body .header .logo img {
  display: block;
  height: 24px;
}
.messageEject {
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  margin-top: 35px;
  box-shadow: 0 2px 5px #ccc;
}
.messageEject dl {
  overflow: hidden;
  padding: 5px 0 0;
}
.messageEject dl dd {
  overflow: hidden;
  line-height: 25px;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}
.messageEject dl dd:nth-last-child(1) {
  border-bottom: none;
}
.messageEject dl dd img {
  display: block;
  float: left;
  width: 36px;
  margin-right: 10px;
}
.messageEject dl dd div {
  overflow: hidden;
  float: left;
  padding-top: 6px;
  max-width: 200px;
  line-height: 20px;
}
.messageEject dl dd div p {
  font-size: 14px;
  margin-bottom: 5px;
}
.messageEject dl dd div span {
  display: block;
  font-size: 13px;
  color: #ccc;
}
.messageEject .operation {
  overflow: hidden;
  display: flex;
  line-height: 45px;
  text-align: center;
}
.ant-btn-primary {
  border-color: #f0f2f3;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-pro-card-header-border {
  padding: 8px 16px !important;
  height: 48px !important;
  /* border-bottom: 1px solid #f0f0f0; */
}

.messageEject .operation a {
  display: block;
  flex: 1;
  font-size: 14px;
}

.hisComponents {
  background: #fff;
  border-radius: 5px;
  margin-top: 0px;
  box-shadow: 0 2px 5px #ccc;
}
.hisComponents dl dd {
  overflow: hidden;
  line-height: 25px;
  padding: 30px 20px 10px;
  border-bottom: 1px solid #ddd;
}
.hisComponents dl dd:nth-last-child(1) {
  border-bottom: none;
}
.hisComponents dl dd img {
  display: block;
  float: left;
  width: 36px;
  margin-right: 10px;
}
.hisComponents dl dd .div1 {
  overflow: hidden;
  float: left;
  padding-top: 6px;
  max-width: 200px;
  line-height: 20px;
}
.hisComponents dl dd .div1 p {
  font-size: 14px;
  margin-bottom: 5px;
}
.hisComponents dl dd .div1 span {
  display: block;
  font-size: 13px;
  color: #ccc;
}
.hisComponents .operation {
  overflow: hidden;
  display: flex;
  line-height: 45px;
  text-align: center;
  border-top: 1px solid #eee;
}
.hisComponents .operation a {
  display: block;
  flex: 1;
  font-size: 14px;
}

.personalSettings {
  overflow: hidden;
  background: #fff;
  border-radius: 5px;
  margin-top: 35px;
  box-shadow: 0 2px 5px #ccc;
}
.personalSettings dl {
  overflow: hidden;
  padding: 3px 0;
}
.personalSettings dl dd {
  overflow: hidden;
  padding: 0 25px 0 15px;
  line-height: 40px;
  cursor: pointer;
}
.personalSettings dl dd:nth-last-child(1) {
  border-top: 1px solid #eee;
}
.personalSettings dl dd div {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.personalSettings dl dd .anticon {
  font-size: 17px;
  margin-right: 7px;
}
.ant-layout-content {
  display: flex;
  height: 100%;
}
.ant-table-tbody tr td {
  border-bottom:0px solid #f0f0f0
}