.show_pdf {
  overflow: hidden;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.show_pdf .head {
  overflow: hidden;
  display: flex;
  align-items: center;
  line-height: 80px;
}
.show_pdf .head .menu_drop {
  overflow: hidden;
  display: flex;
  flex: 1;
  text-align: center;
  margin-right: 3%;
  background: #4f83f3;
  cursor: pointer;
}
.show_pdf .head .menu_drop p {
  flex: 1;
  font-size: 17px;
  color: #fff;
}
.show_pdf .head .information {
  overflow: hidden;
  flex: 5;
}
.show_pdf .head .information ul {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.show_pdf .head .information ul li {
  overflow: hidden;
  flex: 1;
  font-size: 15px;
  padding: 0 2%;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.show_pdf .con {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1;
}
.show_pdf .con .pic {
  overflow: hidden;
  display: flex;
  flex: 1;
}
/* .show_pdf .con .pic .react-pdf__Document {
    display: flex;
    flex: 1;
}
.show_pdf .con .pic .react-pdf__Page {
    display: flex;
    flex: 1;
}
.show_pdf .con .pic canvas {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
} */

.show_pdf .con .operation {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 5%;
  right: 2.5%;
  line-height: 40px;
}
.show_pdf .con .operation button {
  flex: 1;
  border: none;
  font-size: 20px;
  color: #fff;
  padding: 0 20px;
  min-width: 120px;
  line-height: 60px;
  border-radius: 5px;
  background: #4f83f3;
  cursor: pointer;
  margin-bottom: 30px;
  transition: 0.5s all ease;
}
.show_pdf .con .operation button:nth-last-child(1) {
  margin-bottom: 0;
}
.show_pdf .con .operation button:hover {
  box-shadow: 0 0 10px #4f83f3;
}
