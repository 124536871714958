::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: 0 0 6px #ccc;
  background: #999;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 32px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-popover-inner-content {
  padding: 0;
}

.sketch-picker div:nth-last-child(4) {
  padding-bottom: 0 !important;
}
.sketch-picker div:nth-last-child(4) div {
  position: relative !important;
}
.sketch-picker .flexbox-fix:nth-child(3) {
  display: none !important;
}
.sketch-picker .saturation-white {
  display: none;
}

.swatch {
  position: relative;
  width: 28px;
  height: 18px;
  border-radius: 2px;
  top: 4px;
}
.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ProjectSchedule {
  overflow: hidden;
  padding: 0 0 10px 0;
  margin: 0 auto 1%;
  border-radius: 10px;
  box-shadow: 0 0 10px #ccc;
}
.ProjectSchedule .l {
  position: relative;
  flex: 1;
}
.ProjectSchedule .l .title {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
  color: #999;
  padding: 0 15px;
  line-height: 60px;
  box-sizing: border-box;
}
.ProjectSchedule .l .type {
  overflow: hidden;
  line-height: 60px;
  text-align: center;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
  margin-top: 44px;
}
.ProjectSchedule .l .type li {
  overflow: hidden;
  position: relative;
  height: 60px;
}
.ProjectSchedule .l .type li span {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 5px;
  margin: 10px auto 0;
  width: 70%;
  height: 40px;
}
.ProjectSchedule .l .type li p {
  position: absolute;
  top: 48.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  font-size: 12px;
  color: #fff;
  line-height: initial;
}
.ProjectSchedule .r {
  overflow-x: auto;
  overflow-y: hidden;
  flex: 10;
  margin-right: 1%;
}
.ProjectSchedule .r .information {
  overflow: hidden;
  line-height: 27px;
  margin: 0 0 6px 10px;
}
.ProjectSchedule .r .information dd {
  overflow: hidden;
  float: left;
  width: 50%;
}
.ProjectSchedule .r .list {
  overflow: hidden;
  height: 100%;
}
.ProjectSchedule .r .list .con {
  overflow: hidden;
  height: 100%;
}
.ProjectSchedule .r .list .data {
  height: 45px;
  line-height: 45px;
  border-bottom: 1px solid #ccc;
}
.ProjectSchedule .r .list .data .dataList {
  position: relative;
  float: left;
}
.ProjectSchedule .r .list .data .dataList .year {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 15px;
  line-height: 30px;
}
.ProjectSchedule .r .list .data dd {
  position: relative;
  float: left;
  text-align: center;
  font-size: 14px;
}
.ProjectSchedule .r .list .data dd::after {
  position: absolute;
  content: "";
  top: 45px;
  right: 0;
  border-right: 1px dashed #ccc;
  height: 2000px;
  z-index: 1;
}
/* .ProjectSchedule .r .list .data dd:nth-last-child(1)::after {
    display: none;
} */
.ProjectSchedule .r .list ul {
  overflow: hidden;
}
.ProjectSchedule .r .list ul li {
  overflow: hidden;
  position: relative;
  height: 60px;
}
.ProjectSchedule .r .list ul li dl {
  position: absolute;
  top: 50%;
  height: 40px;
  transform: translateY(-50%);
  border-radius: 5px;
}
.ProjectSchedule .r .list ul li dl dd {
  overflow: hidden;
  position: relative;
  float: left;
  height: 40px;
  font-size: 12px;
  color: #fff;
  line-height: 40px;
  background: #999;
  text-align: center;
  transition: 0.5s all ease;
}
.ProjectSchedule .r .list ul li dl dd::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background: #fff;
}
.ProjectSchedule .r .list ul li dl dd:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1;
}
.ProjectSchedule .r .list ul li dl dd:hover::after {
  display: none;
}
.ProjectSchedule .r .list ul li dl dd.cur {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1;
}
.ProjectSchedule .r .list ul li dl dd.cur::after {
  display: none;
}
.ProjectSchedule .r .list ul li dl dd p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 4%;
  font-size: 12px;
  color: #fff;
  line-height: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -moz-box;
  -moz-box-orient: vertical;
  -moz-line-clamp: 2;
  display: -o-box;
  -o-box-orient: vertical;
  -o-line-clamp: 2;
  display: -ms-box;
  -ms-box-orient: vertical;
  -ms-line-clamp: 2;
}

.newList {
  overflow: hidden;
}
.newList .item {
  overflow: hidden;
  padding: 10px;
  margin-bottom: 1%;
}
.newList .item:nth-last-child(1) {
  margin-bottom: 0;
}
.newList .item .btn {
  display: block;
  float: left;
  padding: 0 40px;
  line-height: 40px;
  font-size: 16px;
  color: #666;
  box-shadow: 0 0 10px #666;
  border-radius: 5px;
}
.newList .item .btn:hover {
  color: #fff;
  background: #666;
}

.ProjectSchedule.new {
  display: block;
  padding: 0;
  margin: 0 auto 2%;
}
.ProjectSchedule .t {
  overflow: hidden;
  padding: 0 15px;
  border-bottom: 1px solid #ccc;
}
.ProjectSchedule .b {
  overflow: hidden;
  display: flex;
}
.ProjectSchedule .t .title {
  float: left;
  font-size: 18px;
  color: #999;
  margin-top: 12px;
}
.ProjectSchedule .t .dataChoice {
  overflow: hidden;
  float: right;
  margin: 10px auto;
}
.ProjectSchedule .information {
  overflow: hidden;
  padding: 15px 15px 0;
  border-right: 1px solid #ccc;
}
.ProjectSchedule .information dl {
  overflow: hidden;
}
.new .information dl dd {
  overflow: hidden;
  font-size: 13px;
  color: #666;
  line-height: 27px;
}
.new .l .type {
  margin-top: 0;
  border-top: none;
}
.new .l .type li p {
  font-size: 14px;
  line-height: 30px;
  margin: 14px 30px 0;
}
.new .r {
  flex: 5.5;
  margin: 0 1% 0 0;
}
.new .r .list ul li {
  border-bottom: 1px dashed #ccc;
}
.new .r .list ul li:nth-last-child(1) {
  border-bottom: none;
}
.new .r .list ul li dl {
  height: 32px;
}
.new .r .list ul li dl dd {
  width: 100%;
  height: 32px;
  line-height: 32px;
}
