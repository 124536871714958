@import '../../App.css';

.historyTechnology {
  overflow: hidden;
  line-height: 40px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
}
.historyTechnology li {
  overflow: hidden;
}
.historyTechnology li p {
  font-size: 16px;
}

.order_dismantling {
  overflow: hidden;
  background: #f1f2f6;
}

.order_dismantling .search {
  overflow: hidden;
  margin-bottom: 1%;
}
.order_dismantling .search dl {
  overflow: hidden;
  float: left;
  line-height: 34px;
  margin-right: 30px;
}
.order_dismantling .search dl dd {
  overflow: hidden;
  float: left;
  font-size: 15px;
  color: #fff;
  padding: 0 20px;
  border-radius: 5px;
  margin-right: 15px;
  cursor: pointer;
}
.order_dismantling .search dl dd:nth-last-child(1) {
  margin-right: 0;
}
.order_dismantling .search dl dd:nth-child(1) {
  background: #7f8187;
}
.order_dismantling .search dl dd:nth-child(2) {
  background: #ff8000;
}
.order_dismantling .search dl dd:nth-child(3) {
  background: #306ce5;
}
.order_dismantling .search div {
  overflow: hidden;
  float: left;
}
.order_dismantling .search div input {
  display: block;
  float: left;
  width: 300px;
  padding: 0 15px;
  line-height: 34px;
  border: none;
  border-radius: 5px;
  margin-right: 15px;
}
.order_dismantling .search div dl dd {
  color: #1d244b;
  background: #dcdfe1 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px;
}
.order_dismantling_con {
  overflow: hidden;
  padding: 1% 2%;
  background: #fff;
}
.order_dismantling_con .title {
  overflow: hidden;
  line-height: 41px;
  padding: 10px 13px 0 0;
  margin-bottom: 1%;
}
.order_dismantling_con .title img {
  display: block;
  float: left;
  width: 30px;
  margin: 5px 10px 0 0;
}
.order_dismantling_con .title dl {
  float: left;
}
.order_dismantling_con .title dl dd {
  float: left;
  font-size: 14px;
  margin-right: 20px;
}
.order_dismantling_con .title dl dd p {
  float: left;
}
.order_dismantling_con .title dl dd .ant-scroll-number-only-unit {
  float: none;
}
.order_dismantling_con .title dl dd:nth-last-child(1) {
  margin-right: 0;
}
.order_dismantling_con .title dl:nth-last-child(1) {
  float: right;
}
.order_dismantling_con .con {
  overflow: hidden;
  display: flex;
  padding: 0 5px 5px;
  margin-bottom: 2%;
}
.order_dismantling_con .con .title {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #f5f5f5;
  box-shadow: 0 0 5px #ccc;
}
.order_dismantling_con .con .title p {
  padding: 0 20px;
  font-size: 16px;
  color: #497ec9;
  text-align: center;
}

.order_dismantling_con .con .l {
  position: relative;
  overflow: hidden;
  padding: 91px 10px 15px;
  margin-right: 20px;
  flex: 1;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 5px #dcdcdc;
}
.order_dismantling_con .con .l .title .add {
  display: block;
  float: right;
  padding: 0 20px;
  line-height: 41px;
  border-left: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  z-index: 1;
  transition: 0.5s all ease;
}
.order_dismantling_con .con .l .title .add:hover {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.order_dismantling_con .con .l .operation {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: absolute;
  top: 41px;
  left: 0;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #eee;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
}
.order_dismantling_con .con .l .operation button {
  flex: 1;
  height: 35px;
  border: none;
  border-right: 1px solid #eee;
  box-sizing: border-box;
  padding: 0;
}
.order_dismantling_con .con .l .operation button:nth-last-child(1) {
  border-right: none;
}
.order_dismantling_con .con .l .operation button span:nth-child(1) {
  font-size: 15px;
}
.order_dismantling_con .con .l .operation button span:nth-child(2) {
  margin-left: 4px;
}
.order_dismantling_con .con .l .step {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 450px;
}
.order_dismantling_con .con .l .step .ant-timeline-item-label {
  width: 70px;
  margin-top: -7px;
}
.order_dismantling_con .con .l .step .ant-timeline {
  padding: 25px 0 0 5px;
}
.order_dismantling_con .con .l .step .ant-timeline-item {
  min-height: 33px;
}
.order_dismantling_con .con .l .step .ant-timeline-item:hover .ant-timeline-item-head {
  border-color: #fd3d02 !important;
}
.order_dismantling_con .con .l .step .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50px;
  transition: 0.5s all ease;
}
.order_dismantling_con .con .l .step .t {
  float: left;
  font-size: 13px;
  width: 26px;
  height: 26px;
  line-height: 26px;
  border-radius: 26px;
  text-align: center;
  font-size: 13px;
  color: #fff;
  background: #fd3d02;
  margin-top: -8px;
}
.order_dismantling_con .con .l .step .d {
  display: block;
  float: right;
  width: 38px;
  height: 38px;
  line-height: 37px;
  border-radius: 38px;
  margin-top: -14px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background: #1890ff;
  box-shadow: 0 0 5px #1890ff;
  cursor: pointer;
  transition: 0.5s all ease;
}
.order_dismantling_con .con .l .step .d:hover {
  background: #fd3d02;
  box-shadow: 0 0 5px #fd3d02;
}
.order_dismantling_con .con .l .step .ant-timeline-item-content {
  left: 74px;
  width: 63%;
  line-height: 17px;
  padding-top: 3px;
  cursor: pointer;
}
.order_dismantling_con .con .l .step .ant-timeline-item-content:hover {
  font-size: 14px;
  font-weight: bold;
}
.order_dismantling_con .con .l .step .ant-timeline-item-head {
  left: 80px;
}
.order_dismantling_con .con .l .step .ant-timeline-item-tail {
  left: 84px;
}
.order_dismantling_con .con .o {
  overflow: auto;
  flex: 2;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 5px #dcdcdc;
  margin-right: 20px;
}
.order_dismantling_con .con .o .swiper-container {
  background: #fff;
}
.order_dismantling_con .con .o .tab {
  position: absolute;
  top: 3%;
  left: 2%;
  cursor: pointer;
}
.order_dismantling_con .con .o .tab .anticon {
  display: block;
  float: left;
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 31px;
  border-radius: 30px;
  margin-right: 7px;
  color: #fff;
  background: #929292;
}
.order_dismantling_con .con .o .tab p {
  float: left;
  font-size: 12px;
  color: #929292;
  line-height: 30px;
}
.order_dismantling_con .con .o .banner {
  overflow: hidden;
  background: #eee;
}
.order_dismantling_con .con .o .banner canvas {
  max-height: 450px;
}
.order_dismantling_con .bottom .step {
  overflow: hidden;
  position: relative;
  background: #eee;
  border-radius: 15px;
  padding: 1.5% 4.5%;
  margin-bottom: 2%;
}
.order_dismantling_con .bottom .step #step {
  overflow: initial;
  position: initial;
  text-align: center;
  width: 88%;
  float: left;
}
.order_dismantling_con .bottom .step #step img {
  display: inline-block;
  width: 50px;
  margin: 0 auto 10px;
}
.order_dismantling_con .bottom .step #step p {
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.order_dismantling_con .bottom .step #step .ant-avatar-lg {
  font-size: 15px;
  width: 51px;
  height: 51px;
  line-height: 51px;
  border-radius: 51px;
  cursor: pointer;
}
.order_dismantling_con .bottom .step #step li {
  cursor: pointer;
}
.order_dismantling_con .bottom .step #step li a {
  display: block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-radius: 43px;
  margin: 0 auto;
  background: #008fd7;
  transition: 0.5s all ease;
}
.order_dismantling_con .bottom .step #step li:hover a {
  background: #085abd;
}
.order_dismantling_con .bottom .step #step li.cur div {
  background-color: #ff8000 !important;
}

.order_dismantling_con .bottom .step .step_a {
  overflow: hidden;
  float: left;
  width: 6%;
}
.order_dismantling_con .bottom .step .step_a a {
  display: block;
  width: 51px;
  max-width: 51px;
  height: 51px;
  line-height: 51px;
  border-radius: 51px;
  margin: 0 auto;
  background: #dcdddd;
  transition: 0.5s all ease;
}
.order_dismantling_con .bottom .step .step_a p {
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.order_dismantling_con .bottom .step .step_a:hover a {
  color: #fff;
  background: #085abd;
}

.order_dismantling_con .con .r {
  overflow: hidden;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 55px 10px 15px;
  border: 1px solid #eee;
  box-sizing: border-box;
  box-shadow: 0 2px 5px #dcdcdc;
}
.order_dismantling_con .con .r .step_choice {
  overflow: hidden;
  position: relative;
  flex: 1.3;
  background: #dbeffc;
  border-radius: 20px;
  padding: 6% 4% 2%;
  margin-bottom: 30px;
}
.order_dismantling_con .con .r .step_choice .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 23px;
}
.order_dismantling_con .con .r .step_choice .swiper-button-prev {
  top: 50%;
  left: 0;
}
.order_dismantling_con .con .r .step_choice .swiper-button-next {
  top: 50%;
  right: 0;
}
.order_dismantling_con .con .r .step_choice #step_choice {
  overflow: hidden;
  position: initial;
  width: 86%;
  text-align: center;
}

.order_dismantling_con .con .r .step_choice #step_choice li div {
  width: 43px;
  height: 43px;
  border-radius: 43px;
  margin: 0 auto 5px;
  background-color: #008fd7 !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: 86% !important;
  transition: 0.5s all ease;
}
.order_dismantling_con .con .r .step_choice #step_choice li:hover div {
  background-color: #085abd !important;
}
.order_dismantling_con .con .r .step_choice #step_choice p {
  font-size: 15px;
  color: #1e1e1e;
  line-height: 20px;
}
.order_dismantling_con .con .r .step_choice #step_choice li {
  cursor: pointer;
}
.order_dismantling_con .con .r .step_choice #step_choice li.cur div {
  background-color: #ff8000 !important;
}
.order_dismantling_con .con .r .step_choice dl {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 25px;
}
.order_dismantling_con .con .r .step_choice dl dd {
  overflow: hidden;
  flex: 0 0 25%;
  margin-bottom: 2%;
  cursor: pointer;
  transition: 0.5s all ease;
}

.order_dismantling_con .con .r .step_choice dl dd button {
  width: 94%;
  margin: 0 auto;
  border: none;
}
.order_dismantling_con .con .r .step_choice dl dd:hover button {
  color: #fff;
  background: #306ce5;
}
.order_dismantling_con .con .r .step_choice dl dd.cur {
  color: #fff;
  background: #306ce5;
}
.order_dismantling_con .con .r .step_parameter {
  overflow: hidden;
  position: relative;
  flex: 2;
}
.order_dismantling_con .con .r .step_parameter .ant-empty {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.order_dismantling_con .con .r .empty {
  overflow: hidden;
  position: relative;
  flex: 2;
  border: 1px solid #bdd1de;
  /* background: #bdd1de; */
  border-radius: 20px;
  padding: 7% 9%;
}

.order_dismantling_con .con .r .step_parameter .title {
  font-size: 18px;
  color: #222222;
  text-align: center;
}
.order_dismantling_con .con .r .step_parameter .step_parameter_con {
  overflow: hidden;
  max-height: 450px;
  margin: 0 auto 50px;
}
.order_dismantling_con .con .r .step_parameter ul {
  overflow: hidden;
}
.order_dismantling_con .con .r .step_parameter ul li {
  overflow: hidden;
  display: flex;
  line-height: 30px;
  margin-bottom: 3%;
}
.order_dismantling_con .con .r .step_parameter ul li:nth-last-child(1) {
  margin-bottom: 0;
}
.order_dismantling_con .con .r .step_parameter ul li p {
  width: 30px;
  font-size: 15px;
}
.order_dismantling_con .con .r .step_parameter ul li p:nth-child(1) {
  width: 60px;
  margin-right: 10px;
  text-align: right;
}
.order_dismantling_con .con .r .step_parameter ul li div {
  overflow: hidden;
  flex: 1;
}
.order_dismantling_con .con .r .step_parameter ul li input {
  display: block;
  width: 100%;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  background: #eee;
}
.order_dismantling_con .con .r .step_parameter ul li textarea {
  display: block;
  width: 100%;
  border: none;
  padding: 8px 10px;
  line-height: 20px;
  min-height: 76px;
  border-radius: 5px;
  font-size: 13px;
  background: #eee;
  resize: none;
}
.order_dismantling_con .con .r .step_parameter .btn {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.order_dismantling_con .con .r .step_parameter .btn a {
  display: block;
  flex: 1;
  line-height: 36px;
  border: 1px solid #eee;
  font-size: 13px;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  background: #fff;
  transition: 0.5s all ease;
}
.order_dismantling_con .con .r .step_parameter .btn a:nth-last-child(1) {
  margin-right: 0;
}
.order_dismantling_con .con .r .step_parameter .btn a:hover {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.order_dismantling_con .con .r .step_parameter .btn a:nth-child(2) {
  float: right;
}
.order_dismantling_con .con .r .step_parameter .add {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  border: none;
  line-height: 36px;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  background: #fff;
}

.order_dismantling_con .price {
  overflow: hidden;
  margin-top: 1%;
}
.order_dismantling_con .price dl {
  overflow: hidden;
  float: right;
}
 .price dl dd {
  overflow: hidden;
  float: left;
  margin-right: 20px;
  font-size: 18px;
  color: #fd3d02;
  font-weight: bold;
}
.order_dismantling_con .price dl dd:nth-last-child(1) {
  margin-right: 0;
}

.order_dismantling_operation {
  overflow: hidden;
  text-align: center;
  line-height: 40px;
  background: #fff;
}
.order_dismantling_operation a {
  display: inline-block;
  padding: 0 15px;
  margin: 0 1%;
  font-size: 16px;
  border: 1px solid #dcdddd;
  border-radius: 5px;
}
.order_dismantling_operation a:hover {
  color: #fff;
  background: #0041c2;
  border-color: #0041c2;
}
