@import "../../App.css";

.OrderDetailList .ant-card-body {
    position: relative;
    padding: 24px 50px 24px 24px
}
.OrderDetailList .ant-card-body .btn {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    font-size: 20px;
    padding: 0 5px;
}
.orderOperation {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid #eee;
    box-shadow: 0 2px 5px #ccc;
    margin-top: -10px;
}
.orderOperation .ant-btn {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 44px;
    border: none;
}
.orderOperation .ant-btn .anticon {
    font-size: 21px;
}