@import '../../App.css';

  .container {
    background: #eee;
  }
  .statistics {
    padding: 4%;
    background: linear-gradient(to bottom, #4f83f3 0%, #3c65bd 100%);
    box-shadow: 0 0 20rpx #3c65bd;
  }
  .statistics .title {
    overflow: hidden;
    margin-bottom: 2%;
  }
  .statistics .title text {
    display: block;
    font-size: 36rpx;
    color: #fff;
    line-height: 60rpx;
  }
  .statistics .con {
    overflow: hidden;
  }
  .statistics .con div {
    margin-bottom: 10rpx;
  }
  .statistics .con div p {
    display: block;
    font-size: 26rpx;
    color: #fff;
    line-height: 50rpx;
  }
  .statistics .con div:nth-last-child(1) {
    margin-bottom: 0;
  }
  .process_card {
    margin: 4% 4% 50rpx;
    border-radius: 20rpx 20rpx 0 0;
  }
  .process_card .order_change {
    background: #eee;
    box-shadow: 0 0 20rpx #ccc;
    border-radius: 20rpx;
    margin-bottom: 4%;
  }
  .process_card .order_change .order_title {
    overflow: hidden;
    line-height: 50rpx;
    padding: 3% 4%;
    color: #fff;
    background: #3c65bd;
    border-radius: 20rpx 20rpx 0 0;
    z-index: 10;
  }
  .process_card .order_change .order_title .title {
    overflow: hidden;
  }
  .process_card .order_change .order_title .title p {
    display: block;
    float: left;
    font-size: 34rpx;
    color: #fff;
  }
  .process_card .order_change .order_title .title p:nth-last-child(1) {
    float: right;
  }
  .process_card .order_change .order_title .list {
    overflow: hidden;
    margin-bottom: 2%;
  }
  .process_card .order_change .order_title .list .item {
    overflow: hidden;
  }
  .process_card .order_change .order_title .list .item p {
    display: block;
    float: left;
    font-size: 28rpx;
  }
  .process_card .order_change .order_title .list .item p:nth-child(2) {
    float: right;
  }
  .process_card .order_change .order_con {
    overflow: hidden;
    background: #fff;
    border-radius: 0 0 20rpx 20rpx;
    border: none;
  }
  .process_card .order_change .order_con .tab {
    overflow: hidden;
    padding: 2% 3% 1%;
    line-height: 50rpx;
  }
  .process_card .order_change .order_con .tab p {
    display: block;
    float: left;
    padding: 0 20rpx;
    height: 70rpx;
    line-height: 70rpx;
    text-align: center;
    font-size: 27rpx;
    color: #fff;
    background: #4f83f3;
    box-shadow: 0 0 10rpx #4f83f3;
    margin: 0 20rpx 20rpx 0;
  }
  .process_card .order_change .order_con .tab p:nth-last-child(1) {
    margin: 0 0 20rpx 0;
  }
  .process_card .order_change .order_con .tab p.cur {
    background: orange;
    box-shadow: 0 0 10rpx orange;
  }
  .process_card .order_change .order_con .list {
    overflow: hidden;
  }
  .process_card .order_change .order_con .list .item {
    overflow: hidden;
  }
  .process_card .order_change .order_conduct1 {
    overflow: hidden;
    position: relative;
    margin: 0 4% 4%;
    align-items: center;
    border-top: 1px solid #ddd;
    background: #fff;
  }
  .process_card .order_change .order_conduct1 .title {
    overflow: hidden;
    padding: 10rpx 10rpx 0;
    margin-top: 2%;
    flex: 1;
    line-height: 40rpx;
  }
  .process_card .order_change .order_conduct1 .title .icon_x {
    overflow: hidden;
    float: left;
    width: 85rpx;
    height: 85rpx;
    border-radius: 85rpx;
    background: orange;
    margin-right: 20rpx;
  }
  .process_card .order_change .order_conduct1 .title .icon_x.complete {
    background: #3c65bd;
    box-shadow: 0 0 20rpx #3c65bd;
  }
  .process_card .order_change .order_conduct1 .title .icon_x.progress {
    background: #f17d00;
    box-shadow: 0 0 20rpx #f17d00;
  }
  .process_card .order_change .order_conduct1 .title .icon_x.await {
    background: #999;
    box-shadow: 0 0 20rpx #999;
  }
  .process_card .order_change .order_conduct1 .title .icon_x .uni-icons {
    display: block;
    margin-top: 19rpx;
  }
  .process_card .order_change .order_conduct1 .title .p {
    overflow: hidden;
  }
  .process_card .order_change .order_conduct1 .title .p p {
    display: block;
    font-size: 24rpx;
  }
  .process_card .order_change .order_conduct1 .title .p p:nth-child(1) {
    font-size: 32rpx;
    font-weight: bold;
    margin-bottom: 4rpx;
  }
  .process_card .order_change .order_conduct1 .title .p .operation1 {
    overflow: hidden;
    line-height: 60rpx;
    margin-top: 3%;
    padding-top: 4%;
    border-top: 1px solid #ddd;
  }
  .process_card .order_change .order_conduct1 .title .p .operation1 p {
    display: block;
    float: left;
  }
  .process_card .order_change .order_conduct1 .title .p .operation1 .btn {
    display: block;
    float: right;
    display: flex;
    align-items: center;
  }
  .process_card .order_change .order_conduct1 .title .p .operation1 .btn p {
    display: block;
    font-weight: initial;
    flex: 1;
    font-size: 28rpx;
    color: #333;
    border: 1px solid #3ad1a8;
    background: #fff;
    text-align: center;
    min-width: 90rpx;
    padding: 0 10rpx;
    border-radius: 10rpx;
    line-height: 60rpx;
    margin-right: 20rpx;
  }
  .process_card .order_change .order_conduct1 .title .p .operation1 .btn p:nth-last-child(1) {
    margin-right: 0;
  }
  .process_card .order_change:nth-last-child(1) {
    margin-bottom: 0;
  }
  .qaStlye {
    overflow: hidden;
    width: 560rpx;
    border-radius: 20rpx;
    background: #fff;
  }
  .qaStlye input {
    border: 1px solid #ccc;
    padding: 0 5%;
    height: 80rpx;
    line-height: 80rpx;
    border-radius: 10rpx;
    font-size: 30rpx;
    margin-bottom: 5%;
  }
  .qaStlye .operation {
    overflow: hidden;
    line-height: 60rpx;
    margin-top: 7%;
  }
  .qaStlye .operation .btn {
    display: block;
    display: flex;
    align-items: center;
  }
  .qaStlye .operation .btn p {
    display: block;
    flex: 1;
    font-size: 28rpx;
    color: #333;
    border: 1px solid #3ad1a8;
    background: #fff;
    text-align: center;
    min-width: 90rpx;
    padding: 0 10rpx;
    border-radius: 10rpx;
    line-height: 60rpx;
    margin-right: 40rpx;
  }
  .qaStlye .operation .btn p:nth-last-child(1) {
    margin-right: 0;
  }
  