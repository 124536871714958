@import "../../App.css";

.order_material {
  overflow: hidden;
  padding: 3%;
  background: #eee;
}
.order_material .step {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  text-align: center;
}
.order_material .step li {
  flex: 0 0 18.4%;
  margin: 0 2% 2% 0;
}
.order_material .step li:nth-child(5n) {
  margin: 0 0 2% 0;
}
.order_material .step li a {
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 0 2%;
  line-height: 50px;
  background: #0098de;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* .order_material .step {
  overflow: hidden;
  position: relative;
}
.order_material .step #step {
  overflow: hidden;
  position: initial;
  width: 88%;
  margin: 0 auto;
  text-align: center;
}
.order_material .step #step img {
  display: inline-block;
  width: 50px;
  margin: 0 auto 10px;
}
.order_material .step #step p {
  font-size: 15px;
  color: #fff;
  text-align: center;
}
.order_material .step #step li {
  cursor: pointer;
}
.order_material .step #step li a {
  display: block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 56px;
  margin: 0 auto;
  background: #29aecc;
  transition: 0.5s all ease;
}
.order_material .step #step li:hover a {
  background: #085abd;
}
.order_material .step #step li.cur div {
  background-color: #ff8000 !important;
}
.order_material .step #step .swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 23px;
}
.order_material .step #step .swiper-button-prev {
  top: 50%;
  left: 0;
}
.order_material .step #step .swiper-button-next {
  top: 50%;
  right: 0;
}

.order_material .step .step_a {
  display: block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  border-radius: 43px;
  margin: 0 auto;
  background: #dcdddd;
  transition: 0.5s all ease;
}
.order_material .step .step_a p {
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.order_material .step .step_a:hover {
  color: #fff;
  background: #085abd;
} */


.order_material .con {
  overflow: hidden;
  padding: 3% 3% 2%;
  background: #fff;
  border-radius: 10px;
  margin: 3% auto;
}
.order_material .con .title {
  overflow: hidden;
  margin-bottom: 3%;
}
.order_material .con .title div {
  overflow: hidden;
  float: left;
  line-height: 56px;
  margin-right: 15px;
}
.order_material .con .title div span {
  display: block;
  float: left;
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 56px;
  font-size: 15px;
  text-align: center;
  background: #ffbf00;
}
.order_material .con .title div p {
  float: left;
  font-size: 16px;
  color: #333;
}
.order_material .con .title div:nth-child(2) {
  margin-right: 0;
}
.order_material .con .title div:nth-child(2) p {
  color: #ec1111;
}
.order_material .con .title div:nth-child(2) .anticon {
  font-size: 28px;
  color: #ec1111;
  line-height: 60px;
  background: none;
}
.order_material .con .choice {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.order_material .con .choice dd {
  overflow: hidden;
  flex: 0 0 31%;
  margin: 0 3.5% 2% 0;
}
.order_material .con .choice dd:nth-child(3n) {
  margin: 0 0 2% 0;
}
.order_material .con .choice dd button {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: #fff;
  border-radius: 10px;
  background: #0098de;
  border: none;
}


.order_material .parameter {
  overflow: hidden;
  margin-bottom: 1%;
}
.order_material .parameter .p1 {
  font-size: 15px;
  margin-bottom: 2%;
}
.order_material .parameter ul {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  line-height: 35px;
}
.order_material .parameter ul li {
  display: flex;
  flex: 0 0 31%;
  flex-direction: column;
  margin: 0 2% 1% 0;
}
.order_material .parameter ul li:nth-last-child(1) {
  margin-right: 0;
}
.order_material .parameter ul li p {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  text-indent: 5px;
}
.order_material .parameter ul li input {
  display: block;
  border: none;
  width: 100%;
  padding: 0 10%;
  transition: .5s all ease;
}
.order_material .parameter ul li input:focus {
  box-shadow: 0 0 5px #ccc;
}
.order_material .parameter ul li button {
  display: block;
  width: 100%;
  padding: 0;
  color: #fff;
  height: 35px;
  line-height: 35px;
  background: #085abd;
  transition: .5s all ease;
  border: none;
  box-shadow: initial;
}
.order_material .parameter ul li button:hover {
  box-shadow: 0 0 5px #085abd;
}
.order_material .parameter ul li div {
  overflow: hidden;
  width: 100%;
}
.order_material .parameter ul li dl {
  overflow: hidden;
  display: flex;
  line-height: 25px;
  margin-top: 4%;
}
.order_material .parameter ul li dl dd {
  overflow: hidden;
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 4%;
}
.order_material .parameter ul li dl dd p {
  margin-right: 7%;
  height: 25px;
  line-height: 25px;
}
.order_material .parameter ul li dl dd input {
  flex: 1;
}
.order_material .parameter ul li dl dd:nth-last-child(1) {
  margin-right: 0;
}
.order_material .price {
  font-size: 26px;
  color: #333;
  padding: 4% 2%;
  border-radius: 10px;
  background: #fff;
  text-align: center;
}