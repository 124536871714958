@import "../../App.css";

.table {
  overflow: hidden;
  margin: 2%;
  padding: 10px;
}
.table .table_con {
  overflow-y: auto;
  display: block;
  float: left;
  max-width: 100%;
  line-height: 30px;
  padding-bottom: 20px;
  border: none;
}
.table .table_con tr {
  overflow: hidden;
}
.table .table_con tr td {
  overflow: hidden;
  min-width: 90px;
  padding: 9px 12px;
  position: relative;
  text-align: center;
}
.table .table_con tr:nth-last-child(1) {
  box-shadow: 0px 5px 5px #ddd;
}
.table .table_con tr:nth-child(1) {
  color: #01aaed;
}
.table .table_con tr:nth-child(1) td:nth-child(1) {
  padding: 0;
  min-width: 180px;
}
.table .table_con tr td:nth-child(1) {
  color: #5fb878;
}
.table .table_con tr td:nth-child(1) span {
  color: #5fb878;
}
.table .table_con tr:nth-child(1) td:nth-child(1) div {
  display: initial;
}
.table .table_con tr td div {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.table .table_con tr td div input {
  display: block;
  flex: 1;
  line-height: 29px;
  border: none;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  text-align: center;
}
.table .table_con tr td div span {
  display: block;
  float: left;
  margin: 0 5px;
}
.table .table_con tr:nth-child(1) td:nth-child(1) p {
  overflow: hidden;
  width: 90%;
  line-height: 15px;
  margin: 0 auto;
  font-weight: bold;
}
.table .table_con tr:nth-child(1) td:nth-child(1) p:nth-child(1) {
  text-align: right;
  color: #01aaed;
}
.table .table_con tr:nth-child(1) td:nth-child(1) p:nth-child(2) {
  text-align: left;
  color: #5fb878;
}
.table .table_con tr:nth-child(1) td:nth-child(1)::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 186px;
  top: 0;
  left: 0;
  background: #999;
  transform: rotate(-75deg);
  transform-origin: top;
}
.table .table_con::-webkit-scrollbar {
  height: 10px;
}
.table .table_con::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ccc;
}
.table .table_con::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #ececec;
}

.table .operation {
  margin-top: 2%;
}
.table .operation button {
  display: block;
  float: left;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  padding: 0 15px;
  line-height: 34px;
  border-radius: 34px;
  margin-right: 1%;
  box-shadow: 1px 2px 10px 0 rgba(64, 158, 255, 0.5);
  transition: 0.5s all linear;
  cursor: pointer;
}
.table .operation button:hover {
  color: #fff;
  background: rgba(64, 158, 255);
}
.table .operation button:nth-last-child(1) {
  margin-right: 0;
}
