@import '../../App.css';

.statistics {
  padding: 2%;
  background: linear-gradient(to bottom, #4f83f3 0%, #3c65bd 100%);
}

.statistics .title {
  overflow: hidden;
}

/* .statistics .title .name {
    overflow: hidden;
    display: flex;
    align-items: center;
    float: left;
} */

.statistics .title image {
  display: block;
  float: left;
  width: 30px;
  margin-right: 10px;
}

.statistics .title p {
  display: block;
  float: left;
  font-size: 18px;
  color: #fff;
  margin-right: 10px;
}

.statistics .title .statistics_tab {
  overflow: hidden;
  float: right;
}

.statistics .title .statistics_tab p {
  display: block;
  float: left;
  font-size: 14px;
  color: #fff;
  width: 120rpx;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
  line-height: 28px;
  margin-right: 10px;
  transition: 0.5s all ease;
}

.statistics .title .statistics_tab p:nth-last-child(1) {
  margin-right: 0;
}

.statistics .title .statistics_tab p.cur {
  box-shadow: 0 0 5px #fff inset;
}

.statistics .orders {
  overflow: hidden;
  margin-bottom: 3%;
}

.statistics .orders .list {
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: center;
}

.statistics .orders .list .item {
  overflow: hidden;
  position: relative;
  flex: 1;
  box-sizing: border-box;
}

.statistics .orders .list .item p {
  display: block;
  font-size: 13px;
  color: #fff;
}

.statistics .orders .list .item p:nth-child(1) {
  font-size: 16px;
}

.statistics .orders .list .item::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 60%;
  background: #ccc;
}

.statistics .orders .list .item:nth-last-child(1)::after {
  display: none;
}

.statistics .progress p {
  display: block;
  font-size: 15px;
  color: #fff;
}

.statistics .progress p:nth-last-child(1) {
  text-align: right;
}

.statistics .progress div {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 22.5px;
  line-height: 22.5px;
  background: #f26537;
  box-shadow: 0 0 10px #f26537;
  margin: 3% 0;
  border-radius: 15px;
}

.statistics .progress div span {
  position: absolute;
  left: 0;
  top: 0;
  padding-right: 10px;
  height: 100%;
  font-size: 13px;
  color: #fff;
  text-shadow: 0 0 5px #ccc;
  background: #e93a00;
  text-align: right;
}

.chart {
  overflow: hidden;
  margin-bottom: 5%;
}

.chart .list {
  overflow: hidden;
}

.chart .list .item {
  overflow: hidden;
  position: relative;
  margin-bottom: 3%;
}

.chart .list .item .qiun-charts {
  width: 750upx;
  height: 500upx;
}

.chart .list .item .charts {
  width: 750upx;
  height: 500upx;
  background-color: #ffffff;
}

.chart .list .item .qiun-textarea {
  height: 300upx;
}

.chart .list .item .layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.chart .list .item .tab {
  overflow: hidden;
  float: right;
  margin-top: 11px;
}

.chart .list .item .tab p {
  display: block;
  float: left;
  font-size: 14px;
  color: #4f83f3;
  width: 60px;
  border: 1px solid #4f83f3;
  border-radius: 5px;
  text-align: center;
  line-height: 28px;
  margin-right: 10px;
  transition: 0.5s all ease;
}

.chart .list .item .tab p:nth-last-child(1) {
  margin-right: 0;
}

.chart .list .item .tab p.cur {
  color: #fff;
  background: #4f83f3;
}

.chart .list .item:nth-last-child(1) {
  margin-bottom: 0;
}

.order .tab {
  overflow: hidden;
  float: right;
  margin: 11px 0;
}

.order .tab p {
  display: block;
  float: left;
  font-size: 14px;
  color: #4f83f3;
  width: 60px;
  border: 1px solid #4f83f3;
  border-radius: 5px;
  text-align: center;
  line-height: 28px;
  margin-right: 10px;
  transition: 0.5s all ease;
}

.order .tab p:nth-last-child(1) {
  margin-right: 0;
}

.order .tab p.cur {
  color: #fff;
  background: #4f83f3;
}

.order .order_change {
  background: #eee;
  box-shadow: 0 0 10px #ccc;
  border-radius: 10px;
  margin-bottom: 4%;
}

.order .order_change .order_title {
  overflow: hidden;
  line-height: 25px;
  padding: 3% 4% 0;
  color: #fff;
  background: #3c65bd;
  border-radius: 10px 10px 0 0;
  z-index: 10;
}

.order .order_change .order_title .title {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-bottom: 1%;
}

.order .order_change .order_title .title p {
  display: block;
  flex: 1;
  font-size: 17px;
  color: #fff;
}

.order .order_change .order_title .title p.btn {
  display: block;
  flex: initial;
  float: right;
  font-size: 13px;
  color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-left: 10px;
}

.order .order_change .order_title .title_m {
  display: block;
  font-size: 14px;
  color: #fff;
}

.order .order_change .order_title .list {
  overflow: hidden;
  margin-bottom: 2%;
}

.order .order_change .order_title .list .item {
  overflow: hidden;
}

.order .order_change .order_title .list .item p {
  display: block;
  float: left;
  font-size: 14px;
}

.order .order_change .order_title .list .item p:nth-child(2) {
  float: right;
}

.order .order_change .order_con {
  overflow: hidden;
  background: #fff;
  border-radius: 0 0 10px 10px;
  border: none;
}

.order .order_change .order_con .tab {
  overflow: hidden;
  padding: 2% 3% 1%;
  line-height: 25px;
}

.order .order_change .order_con .tab p {
  display: block;
  float: left;
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background: #4f83f3;
  box-shadow: 0 0 5px #4f83f3;
  margin: 0 10px 10px 0;
}

.order .order_change .order_con .tab p:nth-last-child(1) {
  margin: 0 0 10px 0;
}

.order .order_change .order_con .tab p.cur {
  background: orange;
  box-shadow: 0 0 5px orange;
}

.order .order_change .order_con .list {
  overflow: hidden;
}

.order .order_change .order_con .list .item {
  overflow: hidden;
}

.order .order_change .order_conduct1 {
  overflow: hidden;
  position: relative;
  margin: 0 4% 4%;
  align-items: center;
  border-top: 1px solid #ddd;
  background: #fff;
}

.order .order_change .order_conduct1 .pic {
  overflow: hidden;
  margin-left: 25px;
}

.order .order_change .order_conduct1 .pic .charts3 {
  width: 220upx;
  height: 220upx;
}

.order .order_change .order_conduct1 .pic .charts3.order_charts3 {
  width: 90upx !important;
  height: 90upx !important;
}

.order .order_change .order_conduct1 .pic p {
  display: block;
  font-size: 14px;
  text-align: center;
}

.order .order_change .order_conduct1 .con {
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.order .order_change .order_conduct1 .con .remarks {
  flex: 1;
  text-align: center;
  line-height: 30px;
  padding-top: 14px;
}

.order .order_change .order_conduct1 .con .remarks p {
  display: block;
  font-size: 14px;
}

.order .order_change .order_conduct1 .con .remarks p:nth-child(1) {
  font-size: 30px;
}

.order .order_change .order_conduct1 .icon {
  overflow: hidden;
  padding: 10px;
  margin-right: 10px;
}

.order .order_change .order_conduct1 .icon div {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 180upx;
  height: 155upx;
  padding-top: 25upx;
  border-radius: 180upx;
}

.order .order_change .order_conduct1 .icon div p {
  font-size: 14px;
  color: #000;
}

.order .order_change .order_conduct1 .icon div.qa {
  box-shadow: 0 0 10px #999;
}

.order .order_change .order_conduct1 .icon div.complete {
  box-shadow: 0 0 10px #30c6b7;
}

.order .order_change .order_conduct1 .icon div.overdue {
  box-shadow: 0 0 10px #ea4839;
}

.order .order_change .order_conduct1 .title {
  overflow: hidden;
  padding: 5px;
  margin-top: 2%;
  flex: 1;
  line-height: 20px;
}

.order .order_change .order_conduct1 .title .icon_x {
  overflow: hidden;
  float: left;
  width: 42.5px;
  height: 42.5px;
  border-radius: 42.5px;
  background-color: orange;
  background-image: url(../images/img/star_icon.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 27px;
  box-shadow: 0 0 5px orange;
  margin-right: 10px;
}

.order .order_change .order_conduct1 .title .icon_x .uni-icons {
  display: block;
  margin-top: 9.5px;
}

.order .order_change .order_conduct1 .title .text {
  overflow: hidden;
}

.order .order_change .order_conduct1 .title .text p {
  display: block;
  font-size: 12px;
}

.order .order_change .order_conduct1 .title .text p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

.order .order_change:nth-last-child(1) {
  margin-bottom: 0;
}

.order .operation1 {
  overflow: hidden;
  line-height: 30px;
  padding-top: 3%;
  border-top: 1px solid #ddd;
}

.order .operation1 p {
  display: block;
  float: left;
}

.order .operation1 .btn {
  display: block;
  float: right;
  display: flex;
  align-items: center;
}

.order .operation1 .btn p {
  display: block;
  flex: 1;
  font-size: 14px;
  color: #333;
  border: 1px solid #3ad1a8;
  background: #fff;
  text-align: center;
  min-width: 60px;
  padding: 0 5px;
  border-radius: 5px;
  line-height: 30px;
  margin-right: 10px;
}

.order .operation1 .btn p:nth-last-child(1) {
  margin-right: 0;
}

.tab_con {
  overflow: hidden;
  display: flex;
  align-items: center;
  line-height: 50px;
  border-top: 1px solid #dcdcdc;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  z-index: 10;
}

.tab_con .item {
  position: relative;
  flex: 1;
  font-size: 17px;
  color: #ccc;
  padding: 0 2% 3.5px;
  border-right: 1px solid #dcdcdc;
  box-sizing: border-box;
  transition: 0.5s all ease;
}

.tab_con .item text:nth-child(2) {
  float: right;
}

.tab_con .item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #dcdcdc;
  transition: 0.5s all ease;
}

.tab_con .item.cur {
  color: #0db4c4;
}

.tab_con .item.cur::after {
  height: 3px;
  background: #0db4c4;
}

.tab_con .item:nth-last-child(1) {
  border-right: none;
}

.switch_con {
  overflow: hidden;
  background: #fff;
}

.switch_con .switch {
  overflow: hidden;
}

.switch_con .switch .title {
  display: block;
  padding: 0 5%;
  font-size: 23px;
  color: #333;
  background: #fff;
}

.switch_con .switch .list {
  overflow: hidden;
  padding: 5%;
}

.switch_con .switch .list .item {
  overflow: hidden;
  display: flex;
  background: #fff;
  align-items: center;
  margin-bottom: 10px;
}

.switch_con .switch .list .item .icon {
  overflow: hidden;
  padding: 10px;
  margin-right: 10px;
}

.switch_con .switch .list .item .icon div {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 120upx;
  height: 96upx;
  padding-top: 25upx;
  border-radius: 120upx;
}

.switch_con .switch .list .item .icon div p {
  font-size: 14px;
  color: #fff;
}

.switch_con .switch .list .item .icon div.qa {
  box-shadow: 0 0 10px #999;
}

.switch_con .switch .list .item .icon div.complete {
  box-shadow: 0 0 10px #30c6b7;
}

.switch_con .switch .list .item .icon div.overdue {
  box-shadow: 0 0 10px #ea4839;
}

.switch_con .switch .list .item .pic {
  overflow: hidden;
  margin-right: 10px;
}

.switch_con .switch .list .item .pic .charts3 {
  width: 160upx;
  height: 160upx;
}

.switch_con .switch .list .item .text {
  overflow: hidden;
  flex: 1;
  line-height: 20px;
}

.switch_con .switch .list .item .text p {
  display: block;
  font-size: 12px;
}

.switch_con .switch .list .item .text p:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

.switch_con .switch .list .item:nth-last-child(1) {
  margin-bottom: 0;
}

.more {
  overflow: hidden;
  font-size: 0;
  text-align: center;
}

.more p {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  padding: 0 20px 0 22.5px;
  line-height: 38px;
  border-radius: 5px;
  background: #0db4c4;
  box-shadow: 0 0 10px #0db4c4;
  margin: 15px auto 10px;
}

.item_null {
  overflow: hidden;
  padding: 7% 0;
  text-align: center;
}

.item_null p {
  font-size: 17px;
  color: #0db4c4;
}
