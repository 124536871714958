
@import '~antd/dist/antd.css';

* {
  font-family: 'Microsoft YaHei,Arial';
  margin: 0;
  padding: 0;
  outline: none;
}
body {
  font-size: 12px;
  color: #333;
}
ol,
ul,
li {
  list-style: none;
}
ol,
ul,
li,
dl,
dd,
p {
  margin: 0;
}
img {
  border: none;
}
.ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 2px 8px !important;
}
.ant-pro-card {
  height: 100%;
}
.ant-pro-card-body {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  /* padding: 0px !important; */
}
.ant-card-cover canvas {
  position: relative;
  left: 0;
  display: block;
  width: 100% !important;
  height: 150px !important;
}
.ant-card-cover iframe,
embed {
  width: 100% !important;
  height: 100% !important;
  min-height: 185px;
}
a {
  font-size: 12px;
  color: #333;
  text-decoration: none;
  transition: 0.5s all ease;
}

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #ccc;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #eee;
}

/* 被选中的表格行的样式 */
.clickrowstyl {
  background-color: #cbf2ff !important;
}
.ant-table-tbody  >  tr:hover:not(.ant-table-expanded-row)  >  td,
.ant-table-row-hover,
.ant-table-row-hover > td  {
  background: #cbf2ff !important;
}
.ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover  >  td  {
  background: #cbf2ff !important;
}
.ant-pro-card-body {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  /* padding: 24px; */
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
  max-height: 300px;
}

.order_printing {
  overflow: hidden;
}
.order_printing table {
  display: block;
  line-height: 35px;
  border-collapse: collapse;
  border: none;
}
.order_printing table tr {
  overflow: hidden;
}
.order_printing table tr th {
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 20px;
  line-height: 25px;
}
.order_printing table tr td {
  overflow: hidden;
  padding: 5px 20px;
}

.OrderDetailDrawer .ant-drawer-body {
  padding: 0;
}
 
/* .ant-tabs-tabpane {
  display: flex;
}
.ant-layout-content {
  display: flex;
  position: relative;
} */